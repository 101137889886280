.navbar {
  align-items: center;
  background-color: #C4C4C4;
}

.custom-navbar {
    display: flex;
    flex-direction: row;
}

.custom-navbar .nav-link {
    white-space: nowrap;
}


.bg-red {
  --bs-bg-opacity: 1;
  background-color: #C4C4C4;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-nav .nav-link {
  color: black;
  font-weight: 500;
  transition: color 0.3s, font-size 0.3s;
}

.navbar-light .navbar-nav .nav-link:hover {
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
}

.brand-item {
  padding-right: 45px;
}

.titleLink {
    display: block; /* This will allow the link to accept padding and height */
    /*background-color: #b31b1b;*/
    font-size: 1.2em;
    padding: 10px;
    margin: 0;
    color: black;
    /*height: 50px;  Explicit height excluding padding */
    text-decoration: none; /* To remove any underlines */
}
.titleLink:hover {
    color: black;
    text-decoration: none; /* To ensure the underline doesn't appear on hover */
}

.titleLink:visited {
    color: black;
}

.titleLink:active{
  color: black;
}
.titleLink:focus{
  color: black;
}
.navbar-collapse {
  justify-content: flex-start;
}

.custom-btn {
    transition: all 0.3s;
    /*padding: 5px;*/
}

.custom-btn:hover {
    border: 2px;
    border-radius: 4px;
    background-color: #A1A1A1;
}

.topbar {
	margin-bottom: 20px;
}

.skip {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
}

.seal-logo-fluid {
    height: 2em;
    margin-right: 10px;
}

