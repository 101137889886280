* {
  font-family: Lato, Arial, Helvetica, sans-serif;
}

body {
  background-color: #ffffff;
  color: #444;
}

.big_title {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  /*color: #b31b1b;
    border-bottom: 4px solid #ddd;*/
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
  /*background-color: #f5f5f5;*/
  background-color: #b31b1b;
  color: white;
}

.subtitle-small {
  color: #b31b1b;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  text-align: center;
}

.subtitle-small-two {
  color: #b31b1b;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
}

.subtitle-small-three {
  color: #b31b1b;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.subtitle-small-five {
  color: #b31b1b;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  text-align: center;
}

.subtitle-small-four {
  color: #de2672;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 7px;
  text-align: center;
}

.study-content {
  width: 100%;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  position: relative;
}

@media (min-width: 580px) {
  .study-content {
    max-width: 522px;
  }
}

@media (min-width: 768px) {
  .study-content {
    max-width: 691px;
  }
}

@media (min-width: 900px) {
  .study-content {
    max-width: 810px;
  }
}

@media (min-width: 1200px) {
  .study-content {
    max-width: 1080px;
  }
}

.study-content .subtitle {
  color: #b31b1b;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 10px;
}

.study-content .subtitle-small-two {
  color: #b31b1b;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 5px;
}

.study-content .paragraph {
  padding-bottom: 30px;
}

.study-content .item {
  padding-bottom: 15px;
}

.content-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.main-content {
  padding-right: 20px;
}

.table-of-contents {
  position: sticky;
  position: -webkit-sticky;
  /* For Safari */
  max-height: calc(100vh - 70px);
  /* overflow: auto; */
  padding-right: 20px;
  margin-right: 20px;
  top: 0px;
  /* background: lightgray; */
  /* margin-bottom: auto; */
  padding-top: 10px;
}

.table-of-contents ul {
  background: #b31b1b;
  padding-right: 15px;
}

.table-of-contents li {
  text-decoration-line: underline;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  font-weight: bold;
}

.table-of-contents li:nth-child(n+2) {
  border-top: 1px solid;
}

.content {
  width: 100%;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

@media (min-width: 580px) {
  .content {
    max-width: 522px;
  }
}

@media (min-width: 768px) {
  .content {
    max-width: 691px;
  }
}

@media (min-width: 900px) {
  .content {
    max-width: 810px;
  }
}

@media (min-width: 1200px) {
  .content {
    max-width: 1080px;
  }
}

.content .subtitle {
  color: #b31b1b;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 10px;
}

.content .subtitle-link {
  color: #b31b1b;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 10px;
  text-decoration-line: underline;
}

.content .subtitle-small-two {
  color: #b31b1b;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 5px;
}

.content .paragraph {
  padding-bottom: 30px;
}

.content .item {
  padding-bottom: 15px;
}

.chart-title {
  padding-left: 15px;
}

a:hover {
  color: #ef6c6c;
}

.btn-primary {
  background-color: #b31b1b;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-bottom: 30px;
}

.btn-primary:hover {
  background-color: #ff6f5e;
}

.btnContent {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.pageBtn {
  color: white;
  font-size: 14px;
  padding: 3px 10px;
  background-color: #A1A1A1;
  margin: 0 10px;
  transition: all 0.3s;
  border: 2px;
  border-radius: 4px;
}

.pageBtn:hover {
  border: 2px;
  border-radius: 4px;
  background-color: #797979;
}

.package-btn {
  text-decoration: none;
}

svg :focus {
  outline: none;
}

.slider-container {
  text-align: center;
  margin: 20px 0;
}

.hour-slider {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.time-labels {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  margin: 0 auto;
}

.time-labels span {
  font-size: 12px;
}

.table-col {
  width: 25%;
}

.half-width-input {
  width: 70%;
}

.custom-tooltip {
  font-size: 14px;
  line-height: 1.5;
  max-width: 220px;
}


ul {
  list-style-type: circle;
}

.key {
  color: #b31b1b;
}

.value {
  color: green;
  fontStyle: italic;
  margin-right: 20px;
}

/* Standard Bootstrap grid layout applies for screens wider than 600px */

/* Custom styles for screens smaller than 600px */
@media (max-width: 649px) {
  .container-class .col-sm-4 {
    width: 100%;
    max-width: 100%;
  }
}
